import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Observable, Subject, filter, map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storageMap: Map<string, string> = new Map();
    private valueChangesSubject = new Subject<{ key: string, value: string | null }>();

    constructor(@Inject(PLATFORM_ID) private platformId: string) { }

    public getValue(key: string): string | null {
        if (isPlatformBrowser(this.platformId)) {
            const value = localStorage.getItem(key);
            if (value !== null) {
                this.storageMap.delete(key);
            }
            return value;
        } else {
            return this.storageMap.get(key) || null;
        }
    }

    public setValue(key: string, value: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        } else {
            this.storageMap.set(key, value);
        }
        this.valueChangesSubject.next({ key, value });
    }

    public valueChanges(key: string): Observable<string | null> {
        return this.valueChangesSubject.asObservable().pipe(
            filter(change => change.key === key),
            map(change => change.value)
        );
    }
}
